import React from "react"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const SingleColumn = ({content}) => (
  <div className="single-column-holder">
    <div className="container">
      <h2 className="heading">{content.title}</h2>
      <p className="text">
          {content.text}
      </p>

      <div className="btn-wrapper">
        {
          content.button ? 
          content.button.map((btn, i) => {
            return(
              btn.scheduleButton ?
              <ScheduleADemoButton key={i} buttonColor={btn.buttonColor}/>
              :
              <a href={btn.url} 
                rel="noopener noreferrer" 
                target='_blank'
                key={i} 
                className={classNames('btn', 
                  {"btn-orange" : btn.buttonColor === "orange"},
                  {"btn-black" : btn.buttonColor === "black"},
                  {"btn-blue" : btn.buttonColor === "blue"})}>{btn.btnText}</a>
            )
          }) : ''
        }
        {
          content.ApplyButton ?
            <button 
              className="btn-orange"
              data-paperform-id="billsbykickstart" 
              data-popup-button="1">
                Apply now
              </button>
          : ''
        }
      </div>
    </div>
  </div>
)

export default SingleColumn
