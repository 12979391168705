import React from 'react'
import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import TwoColumn from "../../../components/two-column"
import Layout from "../../../components/layout"
import List from "../../../components/compare-list"
import Lifecycle from "../../../components/lifecycle"
import {lifecycleContent} from "../../../lib/lifecycle-content"
import SingleContent from "../../../components/single-column"

const bannerContent = {
  title: "Connect Billsby to more than 2,000 other applications - no dev required.",
  description: [
    {
      text: "With Zapier you can connect Billsby to more than 2,000 other applications your business uses, triggering workflows at critical points during the customer journey. It's a really powerful way to link Billsby to your business."
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free',
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: 'black',
    }
  ],
  tags: [
    // {
    //   class: 'adoric_roadmap btn-coming-soon',
    //   text: "COMING SOON",
    //   iconDisable: true,
    // }
  ],
  isBanner: true,
  sectionZapier: true,
  image: require('../../../images/zapier-switchboard.svg'),
  svg: true,
  imageName: 'developers svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const bannerContent2 = {
  title: "Chain together as many triggers and actions as you like.",
  description: [
    {
      text: "The main power of Zapier comes from the ability to chain together multiple triggers and actions into one workflow, which could impact literally dozens of tools and systems across your organisation."
    },
    {
      text: "It's incredible to think that you can do all of this with Billsby and Zapier, without ever having to write a single line of code."
    }
  ],
  sectionZapier: true,
  svg: false,
  image: 'chain.png',
  imageName: 'chain',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const listContent = {
  bgColor: '',
  sectionZapier: true,
  content: [
    {
      image: require('../../../images/zapier-trigger-frankenstein.svg'),
      svg: true,
      imageName: 'Zapier-Trigger-Frankenstein',
      title: "Use triggers to make things happen in your other apps and tools.",
      list: [
        {
          subtitle: "Keep systems in the loop",
          text: "When a customer updates their personal details in Billsby, make that change flow through to your other systems"
        },
        {
          subtitle: "Suspend access immediately",
          text: "When customers don't pay, lock them out of your systems immediately. Then automatically reinstate access once they're back on track."
        },
        {
          subtitle: "No development required",
          text: "All of this works with no code - just create zaps in your Zapier control panel to link Billsby to your preferred tools and services."
        },
      ]
    },
  ]
}

const listContent2 = {
  bgColor: '',
  sectionZapier: true,
  position: 'reverse',
  content: [
    {
      title: "Use actions to update Billsby when something happens elsewhere.",
      image: require('../../../images/plan-action.svg'),
      svg: true,
      imageName: 'plan-action',
      list: [
        {
          subtitle: "Update personal details",
          text: "When a customer updates their personal details elsewhere, mirror that change in Billsby."
        },
        {
          subtitle: "Change and cancel plans ",
          text: "When a customer requests a plan change in one system, reflect that change in the billing backend immediately."
        },
        {
          subtitle: "Add and remove feature tags",
          text: " Update customers access and permission levels on a granular basis by manually deploying feature tags."
        },
      ]
    },
  ]
}

const singleContent3 =   {
  title: <>Zapier is coming <span>really</span> soon</>,
  text: "If you want it, let our customer service team know from inside your account and we'll add you to our beta waitlist.An additional fee does apply for your Zapier account, depending on the number of zaps you use.",
}

const zapier = () => {
  return (
    <Layout className="zapier">
      <SEO 
        title="Zapier | Billsby | Powerful, customizable subscription billing software" 
        description="Use Zapier to connect Billsby - the most powerful, customizable and easy-to-integrate subscription billing software - with more than 2000 more applications." 
        url="https://www.billsby.com/product/integrations/zapier"
      />

      <TwoColumn content={bannerContent}/>
      <List compare={listContent}/>
      <Lifecycle content={lifecycleContent} />
      <List compare={listContent2}/>
      <TwoColumn content={bannerContent2}/>
      {/* <SingleContent content={singleContent3} /> */}
    </Layout>
  )
}

export default zapier
